<template>
  <div>
    <div class="facility-list">
      <div class="facility-count bg-primary col-lg-2 col-md-6 col-sm-12 mb-3">
        <div name="total-count">
          Total Facilities
          <span>{{ totalCount && totalCount.toLocaleString() }}</span>
        </div>
      </div>
      <h3 class="text-primary main-title">Registered Facilities</h3>
      <div class="three-download-icon">
        <CButton
          color="primary"
          variant="outline"
          size="sm"
          class="hand-icon action-button download-icon"
          @click="downloadAll()"
        >
          <i class="fas fa-download"></i>
        </CButton>
        <h6 class="font-weight-bold download-text">Facility Admin/Users</h6>
        <CButton
          color="primary"
          variant="outline"
          size="sm"
          class="hand-icon action-button download-icon"
          @click="downloadFJobs()"
        >
          <i class="fas fa-download"></i>
        </CButton>
        <h6 class="font-weight-bold download-text">Job Status Overview</h6>
        <CButton
          color="primary"
          variant="outline"
          size="sm"
          class="hand-icon action-button download-icon"
          @click="downloadFcs()"
        >
          <i class="fas fa-download"></i>
        </CButton>
        <h6 class="font-weight-bold download-text">
          Candidate Recruitment Status
        </h6>
      </div>
      <LoadingOverlay v-if="isFetching" />
      <CustomDataTable
        :striped="striped"
        :bordered="bordered"
        :small="small"
        :items="getItems"
        :fields="fields"
        :items-per-page="small ? limit : 5"
        sorter
        :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
        pagination
      >
        <template #action="{ item, index }">
          <td class="py-2">
            <div class="d-flex">
              <CButton
                color="primary"
                square
                size="sm"
                class="mr-1"
                @click="openEditModel(item, index)"
                >Edit</CButton
              >
              <CButton
                color="primary"
                square
                variant="outline"
                size="sm"
                class="mr-1"
                @click="openConfigModel(item, index)"
                v-c-tooltip="'Config'"
              >
                <CIcon name="cil-settings" />
              </CButton>
              <CButton
                color="primary"
                square
                variant="outline"
                size="sm"
                class="mr-1"
                @click="openUserModel(item, index)"
                v-c-tooltip="'User Report'"
              >
                <CIcon name="cil-user" />
              </CButton>
              <CButton
                color="primary"
                size="sm"
                @click="opengroupfacility(item, index)"
                v-c-tooltip="'Assign Group Facility '"
              >
                <i class="fa-solid fa-plus"></i>
              </CButton>
            </div>
          </td>
        </template>
        <template #download="{ item, index }">
          <td class="py-2 d-flex justify-content-center">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              class="hand-icon action-button"
              @click="candidatesDownload(item, index)"
              v-c-tooltip="'Candidates Download'"
            >
              <i class="fas fa-download"></i>
            </CButton>
          </td>
        </template>
      </CustomDataTable>
    </div>
    <facilityConfig
      v-if="facilityConfigModal.isShowPopup"
      :facilityInfo="facilityConfigModal.facilityData"
      :isShowPopup="facilityConfigModal.isShowPopup"
      @modalCallBack="facilityConfigModalCallBack"
    />
    <facilityEdit
      v-if="facilityEditModal.isShowPopup"
      :facilityInfo="facilityEditModal.facilityData"
      :isShowPopup="facilityEditModal.isShowPopup"
      @modalCallBack="facilityEditModalCallBack"
    />
    <facilityUserReport
      v-if="facilityContactModal.isShowPopup"
      :isShowPopup="facilityContactModal.isShowPopup"
      :facility="facilityContactModal.facilityData"
      @modalCallBack="facilityContactModalCallBack"
    />
    <assignGroupFacility
      v-if="facilityGroupModal.isShowPopup"
      :isShowPopup="facilityGroupModal.isShowPopup"
      :facility="facilityGroupModal.facilityData"
      @modalCallBack="facilityGroupModalCallBack"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import facilityConfig from "@/containers/Facility/FacilityConfig";
import facilityEdit from "@/containers/Facility/FacilityEdit";
import facilityUserReport from "@/containers/Facility/FacilityUserReport";
import assignGroupFacility from "@/containers/Facility/AssignGroupFacility";

import CustomDataTable from "@/components/reusable/CustomDataTable";

export default {
  name: "FacilityList",
  components: {
    facilityConfig,
    facilityEdit,
    facilityUserReport,
    assignGroupFacility,
    CustomDataTable,
  },
  props: {
    fields: {
      type: Array,
      default() {
        return [
          { key: "facility_name", label: "Facility Name", _style: "width:20%" },
          { key: "category", label: "Category", _style: "width:10%" },
          { key: "group", label: "Group", _style: "width:5%" },
          { key: "location", label: "Location", _style: "width:5%" },
          { key: "type", label: "Type", _style: "width:5%" },
          { key: "main_contact", label: "Main Contact", _style: "width:15%" },
          {
            key: "registered",
            label: "Registered",
            _style: "width:10%",
            type: "date",
            format: "DD MMM YYYY",
          },
          {
            key: "last_login",
            label: "Last Login",
            _style: "width:10%",
            type: "date",
            format: "DD MMM YYYY",
          },
          {
            key: "action",
            label: "Action",
            sorter: false,
            filter: false,
            _style: "width:15%",
          },
          {
            key: "download",
            label: "Candidates Download",
            sorter: false,
            filter: false,
            _style: "width:5%",
          },
        ];
      },
    },
    limit: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      isFetching: false,
      small: true,
      striped: true,
      bordered: true,
      sorter: { external: true, resetable: true },
      isModalOpen: false,
      isContactModalOpen: false,
      isUserModalOpen: false,
      facilityEditModal: {
        isShowPopup: false,
        facilityData: null,
      },
      facilityConfigModal: {
        isShowPopup: false,
        facilityData: null,
      },
      facilityContactModal: {
        isShowPopup: false,
        facilityData: null,
      },
      facilityGroupModal: {
        isShowPopup: false,
        facilityData: null,
      },
    };
  },
  computed: {
    ...mapGetters(["getFacilityList", "getCustomerId"]),
    getItems() {
      return this.getFacilityList
        .map((data) => ({
          facility_id: data?.organisation_id,
          customer_uid: data?.customer_uid,
          facility_name: data?.organisation?.name,
          location: data?.customer_organisation_location
            .map((v) => v?.location?.location)
            .join(),
          type: this.facilityType(
            data?.organisation?.government,
            data?.organisation?.private
          ),
          group: this.getGroup(data?.organisation?.group),
          category: data?.organisation?.organisation_type?.organisation_type,
          main_contact: data?.organisation?.main_contact?.name || "--",
          registered: this.formatDate(data?.created_on),
          last_login: this.getLatestLastLogin(
            data?.customer_organisation_contact
          ),
          facilityData: data,
          custom_group_id: data?.organisation?.custom_group_id,
        }))
        .reverse();
    },
    totalCount() {
      return this.getFacilityList.length;
    },
  },
  mounted() {
    this.fetchFacilityList();
  },
  methods: {
    ...mapActions([
      "fetchFacilityList",
      "downloadCandidates",
      "updateFacilityDetails",
      "showToast",
      "downloadAllFacilityDetails",
      "downloadFacilityJobs",
      "downloadCandidateStatus",
    ]),
    getLatestLastLogin(data) {
      if (data) {
        const last_login_contacts = data
          .filter((v) => v?.contact?.user?.last_login_date)
          .map((v) => v?.contact?.user?.last_login_date);
        const latest_Date = new Date(
          Math.max(...last_login_contacts.map((e) => new Date(e)))
        );
        return last_login_contacts.length && latest_Date
          ? this.formatDate(latest_Date)
          : "--";
      }
      return;
    },
    downloadAll() {
      this.downloadAllFacilityDetails({ all_rows: true });
    },
    downloadFJobs() {
      this.downloadFacilityJobs({ all_rows: true });
    },
    downloadFcs() {
      this.downloadCandidateStatus({ all_rows: true });
    },
    formatDate(date) {
      if (date) return moment(date).format("DD MMM YYYY");
      return "--";
    },
    facilityType(isGovernment, isPrivate) {
      return isGovernment && isPrivate
        ? "Both"
        : isGovernment
        ? "Government"
        : "Private";
    },
    getGroup(data) {
      if (data != null) return data != null ? (data ? "Yes" : "No") : "--";
      return "--";
    },
    openConfigModel(item, index) {
      this.facilityConfigModal.isShowPopup = true;
      this.facilityConfigModal.facilityData = item?.facilityData;
    },
    openEditModel(item, index) {
      this.facilityEditModal.isShowPopup = true;
      this.facilityEditModal.facilityData = item?.facilityData;
    },
    facilityEditModalCallBack(action, data) {
      if (action === false) this.facilityEditModal.isShowPopup = false;
      else {
        this.updateFacilityDetails(data).then(() => {
          this.facilityEditModal.isShowPopup = false;
          this.fetchFacilityList();
        });
      }
      this.facilityEditModal.facilityData = null;
    },
    facilityConfigModalCallBack(action, data) {
      if (action === false) {
        this.facilityConfigModal.isShowPopup = false;
        this.facilityConfigModal.facilityData = null;
      }
    },
    closeModal() {},
    candidatesDownload(item, index) {
      const {
        organisation_id,
        organisation: { name: facility_name },
      } = item?.facilityData;
      let organisation_type_id =
        item?.facilityData?.organisation?.organisation_type_id;
      if (organisation_type_id == 4) {
        // 4 - Advertiser
        this.showToast({
          class: "bg-danger text-white",
          message: "Not enough permission!",
        });
        return false;
      }
      this.downloadCandidates({ organisation_id, facility_name });
    },
    openUserModel(item, index) {
      this.facilityContactModal.facilityData = item?.facilityData;
      this.facilityContactModal.isShowPopup = true;
    },
    opengroupfacility(item, index) {
      this.facilityGroupModal.facilityData = item?.facilityData;
      this.facilityGroupModal.isShowPopup = true;
    },
    facilityContactModalCallBack(action) {
      this.facilityContactModal.isShowPopup = false;
      this.facilityContactModal.facilityData = null;
    },
    facilityGroupModalCallBack(action, data) {
      this.facilityGroupModal.isShowPopup = false;
      this.facilityGroupModal.facilityData = null;
      if (data) {
        this.isFetching = true;
        this.fetchFacilityList().then((res) => {
          this.isFetching = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.facility-list {
  margin-bottom: 5rem;
}
.facility-count {
  padding-left: 0px;
  padding-right: 0px;
  div {
    color: $white;
    padding: 3px;
    text-align: center;
    span {
      display: block;
      padding: 3px;
      background-color: $white;
      font-weight: 700;
      color: $color-black;
    }
  }
}
.download-text {
  color: #085d66e6 !important;
  margin: 55px 0px -200px 0px;
  padding-top: 42px;
}
.download-icon {
  margin: 91px 5px 0px 27px;
}
.three-download-icon {
  display: inline-flex;
  float: right;
  margin-top: -87px;
}

@media screen and (max-width: 960px) {
  .download-icon {
    margin: 100px 960px 0px -4px;
    padding-right: 20px;
  }
  .download-text {
    margin: -66px 0px -88px 39px;
    padding-top: 42px;
  }
  .three-download-icon {
    display: flex;
    float: none;
    margin-bottom: 98px;
    flex-direction: column;
  }
}
</style>
