<template>
  <div class="facility-config">
    <CModal
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      color="primary"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">
          {{ facilityInfo.organisation.name }} Configuration
        </h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
      </template>
      <LoadingOverlay v-if="isFetchingFilter" />
      <div>
        <ValidationObserver ref="facilityConfig" v-slot="{ handleSubmit }">
          <form id="facilityConfig" @submit.prevent="handleSubmit(onSubmit)">
            <CRow class="row mb-3">
              <label class="col-lg-6 col-md-12"
                >Total Max Facility Administrator</label
              >
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <TextInput
                    name="MAX_ADMINS"
                    :value="facility.MAX_ADMINS"
                    @change="handleInput"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow class="row mb-3">
              <label class="col-lg-6 col-md-12">
                Total Max Facility Users
                <span class="sm-title">(Recruiters)</span>
              </label>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <TextInput
                    name="MAX_RECRUITERS"
                    :value="facility.MAX_RECRUITERS"
                    @change="handleInput"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow class="row mb-3">
              <label class="col-lg-6 col-md-12"
                >Limit No. of Candidates for Shortlist</label
              >
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <TextInput
                    name="MAX_SHORTLISTS"
                    :value="facility.MAX_SHORTLISTS"
                    @change="handleInput"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow class="row mb-3">
              <label class="col-lg-6 col-md-12"
                >Limit No. of Candidates for Interview</label
              >
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <TextInput
                    name="MAX_INTERVIEWS"
                    :value="facility.MAX_INTERVIEWS"
                    @change="handleInput"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow class="row mb-3">
              <label class="col-lg-6 col-md-12"
                >Limit No. of Candidates for Recruit</label
              >
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <TextInput
                    name="MAX_RECRUITS"
                    :value="facility.MAX_RECRUITS"
                    @change="handleInput"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow class="row mb-3">
              <label class="col-lg-6 col-md-12"
                >Expiry Days for Shortlist</label
              >
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <TextInput
                    name="SHORTLIST_EXPIRY_TIME"
                    :value="facility.SHORTLIST_EXPIRY_TIME"
                    @change="handleInput"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow class="row mb-3">
              <label class="col-lg-6 col-md-12"
                >Expiry Days for Interview</label
              >
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <TextInput
                    name="INTERVIEW_EXPIRY_TIME"
                    :value="facility.INTERVIEW_EXPIRY_TIME"
                    @change="handleInput"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow class="row mb-3">
              <label class="col-lg-6 col-md-12">Expiry Days for Recruit</label>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <TextInput
                    name="RECRUITED_EXPIRY_TIME"
                    :value="facility.RECRUITED_EXPIRY_TIME"
                    @change="handleInput"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow class="row mb-3">
              <label class="col-lg-6 col-md-12">Max Jobs to be posted</label>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <ValidationProvider
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <TextInput
                    name="MAX_JOBS_TO_BE_POSTED"
                    :value="facility.MAX_JOBS_TO_BE_POSTED"
                    @change="handleInput"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>


<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

import TextInput from "@/components/reusable/Fields/TextInput";

import { extend } from "vee-validate";
import { required, numeric } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("numeric", { ...numeric, message: "Only Numeric value allowed" });

export default {
  components: {
    TextInput,
  },
  props: {
    facilityInfo: {
      type: Object,
      default: () => null,
    },
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      facility: {},
      payload: {},
    };
  },
  computed: {
    ...mapGetters(["getFacilityConfig", "isFetchingFilter"]),
    facilities() {
      if (this.getFacilityConfig.length) {
        return this.getFacilityConfig.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.key]: item.value.replace("d", "") }),
          {}
        );
      }
      return {};
    },
  },
  methods: {
    ...mapActions(["fetchFacilityConfig", "updateFacilityConfig", "showToast"]),
    modalCallBack(action) {
      this.$emit("modalCallBack", action);
    },
    async handleInput(key, value) {
      Vue.set(this.facility, key, value);
      if (value && /^\d+$/.test(value)) {
        if (
          [
            "RECRUITED_EXPIRY_TIME",
            "INTERVIEW_EXPIRY_TIME",
            "SHORTLIST_EXPIRY_TIME",
          ].includes(key)
        )
          value = "d" + value;
        const {
          customer_uid: customer_id,
          organisation_id,
        } = this.facilityInfo;
        this.updateFacilityConfig({
          key,
          value,
          customer_id,
          organisation_id,
        });
      }
    },
  },
  mounted() {
    const { customer_uid: customer_id, organisation_id } = this.facilityInfo;
    this.fetchFacilityConfig({ customer_id, organisation_id }).then(() => {
      if (this.getFacilityConfig.length) {
        this.facility = this.getFacilityConfig.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.key]: item.value.replace("d", "") }),
          {}
        );
      }
    });
  },
};
</script>