<template>
  <div>
    <CModal
      :show.sync="isShowPopup"
      :close-on-backdrop="true"
      color="primary"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">
          Group Facility - {{ facility.organisation.name }}
        </h6>
        <CButtonClose
          @click="modalCallBack(false)"
          class="text-black primary"
        />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
      </template>
      <div v-if="isLoading" class="d-flex justify-content-center">
        <CSpinner color="primary" style="width: 4rem; height: 4rem" />
      </div>
      <div v-else>
        <ValidationObserver ref="assignGroupFacility" v-slot="{ handleSubmit }">
          <form
            id="assignGroupFacility"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <CRow class="mb-3">
              <CCol md="5" v-if="getCustomGroups.length">
                <CRow>
                  <label class="col-lg-12 col-md-12 required"
                    >Select the Group to be mapped</label
                  >
                  <CCol md="12" lg="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="custom_group_id"
                        :value="uploadData.custom_group_id"
                        :options="
                          options && options['groups'] ? options['groups'] : []
                        "
                        @change="handleChangeSelect"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="5" v-if="isNewGroup">
                <CRow>
                  <label class="col-lg-12 col-md-12 required"
                    >Facility Group Name</label
                  >
                  <CCol md="12" lg="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="group_name"
                        :value="uploadData.group_name"
                        @input="handleInput"
                        :error="errors[0]"
                        placeholder="Type the new Group Name"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="2" class="d-flex align-items-end">
                <div>
                  <CButton
                    v-if="
                      facility.organisation.custom_group_id !=
                      uploadData.custom_group_id
                    "
                    type="button"
                    color="primary"
                    @click="onSubmit"
                    :disabled="isSubmitting"
                    >Add
                    <CSpinner
                      v-if="isSubmitting"
                      size="sm"
                      class="ml-1"
                      color="light"
                    />
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
        <div>
          <h6 class="text-primary">
            List of Facilities in this Group
            <span
              class="ml-1"
              v-if="
                uploadData.custom_group_id && uploadData.custom_group_id.code && uploadData.custom_group_id.label
              "
              >[{{ uploadData.custom_group_id.label }}]</span
            >
          </h6>
          <div class="table-responsive border">
            <table class="table mb-0 table-striped w-100">
              <colgroup>
                <col span="1" style="width: 80%" />
                <col span="1" style="width: 20%" />
              </colgroup>
              <thead>
                <tr>
                  <th>Facility Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="facility in groupFacilities"
                  :key="facility.organisation_id"
                >
                  <td>
                    {{ facility.facility_name
                    }}<small class="ml-1 text-primary" v-if="facility.isCurrent"
                      >(Current)</small
                    >
                  </td>
                  <td>
                    <span class="cursor-action" @click="unLinkGroup(facility)">
                      <i class="fa-solid fa-trash-can"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="!groupFacilities.length">
                  <td colspan="2">
                    <p class="text-muted text-center mt-2">No Record Found</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import Select from "@/components/reusable/Fields/Select";
import TextInput from "@/components/reusable/Fields/TextInput";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });

export default {
  props: {
    facility: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Select,
    TextInput,
  },
  data() {
    return {
      uploadData: {},
      uploadPayload: {},
      isSubmitting: false,
      facilityList: [],
      isLoading: false,
      isUpdated: false,
    };
  },
  computed: {
    ...mapGetters(["getCustomGroups", "getFacilityList"]),
    options() {
      return {
        groups: this.groups,
      };
    },
    groups() {
      return [
        ...(this.getCustomGroups?.map((v) => ({
          code: v?.custom_group_id,
          label: v?.group_name,
        })) || []),
        { code: 0, label: "New Group" },
      ];
    },
    isNewGroup() {
      return (
        this.getCustomGroups?.length === 0 ||
        this.uploadPayload?.custom_group_id === 0
      );
    },
    groupFacilities() {
      if (this.uploadPayload?.custom_group_id) {
        const { custom_group_id } = this.uploadPayload;
        return this.facilityList
          ?.filter((v) => v?.organisation?.custom_group_id === custom_group_id)
          .map((v) => ({
            facility_name: v?.organisation?.name,
            custom_group_id: v?.custom_group_id || null,
            custom_group: v?.custom_group || null,
            organisation_id: v?.organisation_id,
            isCurrent: v?.isCurrent || false,
          }));
      }
      return [];
    },
  },
  methods: {
    ...mapActions([
      "fetchCustomGroups",
      "createCustomGroup",
      "updateFacilityDetails",
      "showToast",
    ]),
    handleInput(name, value) {
      Vue.set(this.uploadData, name, value);
      this.uploadPayload[name] = value;
    },
    async handleChangeSelect(name, value) {
      Vue.set(this.uploadData, name, value);
      this.uploadPayload = {
        ...this.uploadPayload,
        [name]: value ? value.id || value.code : null,
      };
      return;
    },
    async onSubmit() {
      const isValid = await this.$refs.assignGroupFacility.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.isSubmitting = true;
      let appendAction = [];
      if (this.isNewGroup) {
        const groupPayload = {
          group_name: this.uploadPayload?.group_name,
        };
        appendAction = [this.createCustomGroup(groupPayload)];
      }
      Promise.all(appendAction).then(async (res) => {
        this.isUpdated = true;
        let custom_group_id = this.uploadPayload?.custom_group_id;
        if (this.isNewGroup && res[0]?.status === 200) {
          const { data } = res[0];
          custom_group_id = data?.custom_group_id;
        }
        if (custom_group_id) {
          let payload = {
            organisation_id: this.facility?.organisation_id,
            custom_group_id,
          };
          await this.updateFacilityDetails(payload).then((res) => {
            if (res?.status === 200) {
              this.findAndUpdate(this.facility?.organisation_id, res.data);
            }
          });
          if (this.isNewGroup) this.fetchCustomGroups();
          this.isSubmitting = false;
          this.resetForm();
          this.initSetup();
        }
      });
    },
    resetForm() {
      this.uploadData = {};
      this.uploadPayload = {};
      this.$nextTick(() => {
        this.$refs.assignGroupFacility.reset();
      });
    },
    unLinkGroup(facility) {
      const payload = {
        custom_group_id: null,
        organisation_id: facility?.organisation_id,
      };
      this.isUpdated = true;
      this.updateFacilityDetails(payload).then((res) => {
        if (res?.status === 200) {
          this.findAndUpdate(facility?.organisation_id, res?.data);
        }
      });
    },
    findAndUpdate(organisation_id, data) {
      const index = this.facilityList.findIndex(
        (v) => v.organisation_id === organisation_id
      );
      if (index >= 0 && this.facilityList[index]) {
        this.facilityList[index].organisation = {
          ...data,
        };
        this.facilityList[index].isCurrent =
          organisation_id === this.facility?.organisation_id;
      }
    },
    modalCallBack(action) {
      this.$emit("modalCallBack", action, this.isUpdated);
    },
    async initSetup() {
      const facility = this.facilityList.find(
        (v) => v?.organisation_id === this.facility?.organisation_id
      );
      if (facility) {
        const custom_group = {
          code: facility?.organisation?.custom_group?.custom_group_id,
          label: facility?.organisation?.custom_group?.group_name,
        };
        await this.handleChangeSelect("custom_group_id", custom_group);
      }
      return;
    },
  },
  mounted() {
    this.facilityList = this.getFacilityList.map((v) => ({
      ...v,
      isCurrent: v?.organisation_id === this.facility?.organisation_id,
    }));
    this.isLoading = true;
    this.fetchCustomGroups().then((res) => {
      this.isLoading = false;
    });
    this.initSetup();
  },
};
</script>
