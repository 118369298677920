<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Facility Edit</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Cancel</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit"
          >Update</CButton
        >
      </template>
      <div>
        <LoadingOverlay v-if="isFetchingFilter" />
        <ValidationObserver
          ref="editFacilityDetail"
          v-slot="{ handleSubmit }"
          v-else
        >
          <form
            id="editFacilityDetail"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <h5 class="mb-3 text-primary">Facility Details</h5>
            <CRow>
              <CCol lg="6">
                <CRow class="row mb-2">
                  <label class="required col-lg-12 col-md-12"
                    >Facility Name</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="name"
                        :value="facility.name"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow class="row mb-2">
                  <label class="required col-lg-12 col-md-12">Category</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="organisation_type_id"
                        :value="facility.organisation_type_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['category']
                            ? options['category']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                        :disabled="isAdvertiser"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow class="row mb-2">
                  <label class="required col-lg-12 col-md-12">Group</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="group"
                        :value="facility.group"
                        :options="
                          options && options['group'] ? options['group'] : []
                        "
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow class="row mb-2">
                  <label class="required col-lg-12 col-md-12">Type</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="type"
                        :value="facility.type"
                        :options="
                          options && options['type'] ? options['type'] : []
                        "
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow class="row mb-2">
                  <label class="col-lg-12 col-md-12">Location</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="location_id"
                      :value="facility.location_id"
                      @input="handleChangeSelect"
                      :options="
                        options && options['locations']
                          ? options['locations']
                          : []
                      "
                      :taggable="false"
                      :multiple="false"
                      :clearable="false"
                      :disabled="true"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow class="row mb-2">
                  <label class="col-lg-12 col-md-12">Facility Address</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <TextInput
                      name="address"
                      :value="facility.address"
                      @input="handleInput"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow class="row mb-2">
                  <label class="col-lg-12 col-md-12">Website</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      :rules="{
                        website_rule: /^(http\:\/\/|https\:\/\/)?([a-z0-9][a-z0-9\-]*\.)+[a-z0-9][a-z0-9\-]*$/,
                      }"
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="website"
                        :value="facility.website"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow class="row mb-2">
                  <label class="col-lg-12 col-md-12">Phone</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <PhoneInput
                      name="phone_no"
                      :value="facility.phone_no"
                      :options="
                        options && options['dialCode']
                          ? options['dialCode']
                          : []
                      "
                      label="dialing_code"
                      @change="handleInput"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <div>
              <h5
                class="border-top pt-2 text-primary mt-1 cursor-action"
                @click="isAdvancedVisible = !isAdvancedVisible"
              >
                Additional Information<span class="px-2"
                  ><CIcon v-if="!isAdvancedVisible" size="lg" name="cil-plus" />
                  <CIcon v-else size="lg" name="cil-minus"
                /></span>
              </h5>
              <div v-show="isAdvancedVisible">
                <CRow>
                  <CCol lg="6">
                    <CRow class="row mb-2">
                      <label class="col-lg-12 col-md-12">No. of Beds</label>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider rules="numeric" v-slot="{ errors }">
                          <TextInput
                            name="num_beds"
                            :value="facility.num_beds"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol lg="6">
                    <CRow class="row mb-2">
                      <label class="col-lg-12 col-md-12"
                        >Facility License No</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <TextInput
                          name="facility_license_no"
                          :value="facility.facility_license_no"
                          @input="handleInput"
                        />
                      </div>
                    </CRow>
                  </CCol>
                  <CCol lg="6">
                    <CRow class="row mb-2">
                      <label class="col-lg-12 col-md-12">Additional Info</label>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <TextInput
                          name="additional_info"
                          :value="facility.additional_info"
                          @input="handleInput"
                        />
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { times } from "lodash";
import Stepper from "@/components/reusable/bars/stepper.vue";
import { extend } from "vee-validate";
import { required, email, min, max, numeric } from "vee-validate/dist/rules";
import { regex } from "vee-validate/dist/rules";
import { deepClone } from "@/helpers/helper.js";

import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";

extend("required", { ...required, message: "This field is required" });
extend("facility_name", { ...max, message: "Max length: 100 character" });
extend("email", { ...email, message: "Invalid email" });
extend("website_rule", { ...regex, message: "Invaild Website" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters",
});
extend("numeric", { ...numeric, message: "Only Numeric value allowed" });

export default {
  components: {
    Select,
    TextInput,
    RadioButton,
    PhoneInput,
    Stepper,
  },
  props: {
    facilityInfo: {
      type: Object,
      default: () => null,
    },
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isAdvancedVisible: false,
      facility: {},
      payload: {},
    };
  },
  computed: {
    ...mapGetters([
      "locations",
      "titles",
      "getOrganisationTypeList",
      "locationCountryInfo",
      "dialingCodeWithCountryCode",
      "getCustomerDetails",
      "isFetchingFilter",
    ]),

    options() {
      return {
        locations: this.locations || [],
        type: [
          { id: 1, label: "Goverment" },
          { id: 2, label: "Private" },
          { id: 3, label: "Both" },
        ],
        group: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        category: this.getOrganisationTypeList || [],
        titles: this.titles || [],
        dialCode: this.dialingCodeWithCountryCode || [],
      };
    },
    isAdvertiser() {
      let organisation_type = this.facility?.organisation_type_id?.label
      return organisation_type == "Advertiser" ? true : false;
    }
  },
  methods: {
    ...mapActions(["showToast", "initOrganisationFetchOptions"]),
    handleInput(name, value) {
      Vue.set(this.facility, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.facility, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.facility, name, value);
      if (name === "type") {
        this.payload = {
          ...this.payload,
          government: val === 1 || val === 3,
          private: val === 2 || val === 3,
        };
      } else {
        this.payload = {
          ...this.payload,
          [name]: val,
        };
      }
    },
    facilityType(organisation) {
      const { government, private: isPrivate } = organisation;
      if (government && isPrivate) return 3;
      return government ? 1 : 2;
    },
    modalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
    async onSubmit() {
      const isValid = await this.$refs.editFacilityDetail.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.payload = {
        ...this.payload,
        organisation_id: this.facility?.organisation_id,
      };
      this.modalCallBack(true, this.payload);
    },
    getFacilityLocation(data) {
      const facilityLocation =
        data.map((v) => ({
          code: v?.location?.location_id,
          label: v?.location?.location,
        })) || [];
      return facilityLocation.length ? facilityLocation[0] : null;
    },
  },
  mounted() {
    this.initOrganisationFetchOptions();
    const facilityData = this.facilityInfo;
    this.facility = {
      name: facilityData?.organisation?.name,
      organisation_type_id: facilityData?.organisation?.organisation_type_id
        ? {
            code:
              facilityData?.organisation?.organisation_type
                ?.organisation_type_id,
            label:
              facilityData?.organisation?.organisation_type?.organisation_type,
          }
        : null,
      group: facilityData?.organisation?.group,
      location_id: facilityData?.customer_organisation_location
        ? this.getFacilityLocation(facilityData?.customer_organisation_location)
        : null,
      type: this.facilityType(facilityData?.organisation),
      website: facilityData?.organisation?.website,
      address: facilityData?.organisation?.address,
      phone_no: facilityData?.organisation?.phone_no,
      num_beds: facilityData?.organisation?.num_beds,
      facility_license_no: facilityData?.organisation?.facility_license_no,
      additional_info: facilityData?.organisation?.additional_info,
      organisation_id: facilityData?.organisation_id,
    };
  },
};
</script>
<style lang="scss" scoped>
.fz-1 {
  font-size: 0.8rem;
}
.cursor-action {
  cursor: pointer;
}
</style>

