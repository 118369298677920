<template>
  <div>
    <div v-if="!customerAdminModal.isShowPopup">
      <CModal
        :show.sync="isShowPopup"
        :close-on-backdrop="false"
        color="primary"
        size="xl"
      >
        <template #header>
          <h6 class="modal-title">Facility Admin / User Report</h6>
          <CButtonClose @click="closeModal" class="text-black primary" />
        </template>
        <template #footer>
          <CButton
            type="button"
            color="primary"
            class="px-4"
            @click="closeModal"
            >OK</CButton
          >
        </template>
        <LoadingOverlay v-if="!facilityUsers.length" />
        <div v-else>
          <h6>{{ facility.facility_name }}</h6>
          <CDataTable
            :striped="striped"
            :bordered="bordered"
            :small="small"
            :items="facilityUsers"
            :fields="fields"
            :items-per-page="limit"
            pagination
          >
            <template #action="{ item }">
              <td>
                <a
                  v-if="item.isCustomerAdmin"
                  class="btn pt-0 pb-0 px-2 fill"
                  :class="{ disabled: !item.isCustomerAdmin }"
                  :disabled="!item.isCustomerAdmin"
                  v-c-tooltip="{
                    content: 'Delete Customer Admin',
                    placement: 'left',
                  }"
                  @click="openCustomerAdminModal(item)"
                >
                  <i class="fa-solid fa-user-gear"></i>
                </a>
                <span v-else class="px-2 fill">--</span>
              </td>
            </template>
            <template #first_name="{ item }">
              <td>
                <span :class="item.colorapply">{{ item.first_name }}</span>
              </td>
            </template>
            <template #last_name="{ item }">
              <td>
                <span :class="item.colorapply">{{ item.last_name }}</span>
              </td>
            </template>
            <template #full_name="{ item }">
              <td>
                <span :class="item.colorapply">{{ item.full_name }}</span>
              </td>
            </template>
            <template #email="{ item }">
              <td>
                <span :class="item.colorapply">{{ item.email }}</span>
              </td>
            </template>
            <template #role="{ item }">
              <td>
                <span :class="item.colorapply">{{ item.role }}</span>
              </td>
            </template>
            <template #last_login="{ item }">
              <td>
                <span :class="item.colorapply">{{ item.last_login }}</span>
              </td>
            </template>
          </CDataTable>
        </div>
      </CModal>
    </div>
    <CustomerAdminModal
      v-if="customerAdminModal.isShowPopup"
      :isShowPopup="customerAdminModal.isShowPopup"
      :facility="facility"
      :user="customerAdminModal.user"
      @modalCallBack="customerAdminModalCallBack"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import { Role } from "@/helpers/helper.js";
import CustomerAdminModal from "@/containers/Facility/CustomerAdminModal";
export default {
  props: {
    facility: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CustomerAdminModal,
  },
  data() {
    return {
      darkModal: false,
      small: true,
      striped: true,
      bordered: true,
      fields: [
        {
          key: "first_name",
          label: "First Name",
        },
        {
          key: "last_name",
          label: "Last Name",
        },
        {
          key: "full_name",
          label: "Full Name",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "role",
          label: "Role",
        },
        {
          key: "last_login",
          label: "Last Login",
        },
        { key: "action", label: "Action" },
      ],
      limit: 10,
      customerAdminModal: {
        isShowPopup: false,
        user: null,
      },
    };
  },
  computed: {
    ...mapGetters(["getFacilityUsers"]),
    facilityUsers() {
      return this.getFacilityUsers.map((data) => ({
        first_name: this.toTitleCase(data?.user?.first_name),
        last_name: this.toTitleCase(data?.user?.surname),
        full_name: this.toTitleCase(
          `${data?.user?.first_name} ${data?.user?.surname}`
        ),
        email: data?.user?.email,
        role: this.getUserRoles(data?.user?.roles),
        last_login: this.formatDate(data.user?.last_login_date),
        isCustomerAdmin: this.checkIsCustomerAdmin(data?.user?.roles),
        rawData: data,
        colorapply: this.ApplyColor(data?.customer_organisation_contact)
          ? "fill"
          : "",
      }));
    },
  },
  methods: {
    ...mapActions(["fetchFacilityUserReport", "updateCustomerUser"]),
    closeModal() {
      this.$emit("modalCallBack", false);
    },
    getRole(data) {
      if (data != null) {
        const roles = {
          customer_admin: "Admin",
          customer_recruiter: "Recruiter",
        };
        const { role_type } = data[0];
        return roles[role_type] || "--";
      }
      return "--";
    },
    ApplyColor(customer_contacts) {
      return customer_contacts?.some(
        (ele) =>
          ele.mapped_organisation_id == this.facility.organisation_id &&
          !ele.is_primary
      );
    },
    formatDate(data) {
      if (data != null) {
        return moment(data).format("Do MMM YYYY");
      }
      return "--";
    },
    toTitleCase(str) {
      if (str)
        return str.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      return "--";
    },
    getUserRoles(data) {
      if (data)
        return (
          data.map(({ role_type }) => _.startCase(role_type)).join() || "--"
        );
      return "--";
    },
    checkIsCustomerAdmin(data) {
      return (
        data?.some(({ role_type }) => role_type === Role.customerAdmin) || false
      );
    },
    openCustomerAdminModal(item) {
      this.customerAdminModal.isShowPopup = true;
      this.customerAdminModal.user = item.rawData;
    },
    customerAdminModalCallBack(action, data) {
      if (action && data) {
        const { customer_uid, organisation_id } = this.facility;
        const payload = { ...data, organisation_id };
        this.updateCustomerUser(payload).then((res) => {
          if (res?.status === 200) {
            this.fetchFacilityUserReport({ customer_uid, organisation_id });
            this.customerAdminModal.isShowPopup = false;
          }
        });
      } else this.customerAdminModal.isShowPopup = false;
    },
  },
  mounted() {
    const { customer_uid, organisation_id } = this.facility;
    this.fetchFacilityUserReport({ customer_uid, organisation_id });
  },
};
</script>
<style>
.fill {
  color: #085d66e6;
  font-weight: 450;
}
</style>
