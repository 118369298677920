<template>
  <div>
    <CModal
      :show.sync="isShowPopup"
      :close-on-backdrop="true"
      color="primary"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Change Customer Admin</h6>
        <CButtonClose
          @click="modalCallBack(false)"
          class="text-black primary"
        />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Cancel</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit"
          >Submit</CButton
        >
      </template>
      <div>
        <ValidationObserver ref="changeCustomerAdmin" v-slot="{ handleSubmit }">
          <form
            id="changeCustomerAdmin"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <CRow class="mb-3">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12 required">First Name</label>
                  <CCol md="12" lg="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="first_name"
                        :value="uploadData.first_name"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12 required">Last Name</label>
                  <CCol md="12" lg="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="last_name"
                        :value="uploadData.last_name"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow name="phone" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Phone</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      ref="phone"
                      :rules="{
                        required: true,
                        phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                      }"
                      v-slot="{ errors }"
                    >
                      <PhoneInput
                        name="phone"
                        :value="uploadData.phone"
                        :options="
                          options && options['dialingCode']
                            ? options['dialingCode']
                            : []
                        "
                        :error="errors[0]"
                        @change="handleInput"
                        ref="phoneNo"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="email" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Email</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="email"
                        :value="uploadData.email"
                        :error="errors[0]"
                        @input="handleInput"
                        autocomplete="off"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="password" class="mb-3">
                  <label class="col-lg-12 col-md-12">Password</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="required"
                      name="password"
                      v-slot="{ errors }"
                    >
                      <PasswordInput
                        name="password"
                        :value="autoGenPassword"
                        @input="handleInput"
                        :error="errors[0]"
                        autocomplete="off"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Select from "@/components/reusable/Fields/Select";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInputwithCountry";
import PasswordInput from "@/components/reusable/Fields/PasswordInput";
import { extend } from "vee-validate";
import { required, regex, numeric, email } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
export default {
  name: "changeCustomerAdmin",
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
    facility: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Select,
    TextInput,
    PhoneInput,
    PasswordInput,
  },
  data() {
    return {
      uploadData: {},
    };
  },
  computed: {
    ...mapGetters(["dialingCodeWithCountryCode", "autoGenPassword"]),
    options() {
      return {
        dialingCode: this.dialingCodeWithCountryCode || [],
      };
    },
  },
  methods: {
    ...mapActions(["showToast", "fetchAutoGenPassword"]),
    handleInput(name, value) {
      let hasError = {
        phone: "phone",
      };
      if (name !== hasError.phone || (name == hasError.phone && value !== "")) {
        Vue.set(this.uploadData, name, value);
      }
      if (name === hasError.phone && value === "") {
        this.clearFieldErrors(name, hasError);
      }
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name === hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        }
      });
    },
    async onSubmit() {
      const isValid = await this.$refs.changeCustomerAdmin.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }
      const {
        contact_id,
        user_id,
        user: { customer_id },
      } = this.user;
      const payload = {
        ...this.uploadData,
        contact_id,
        user_id,
        customer_id,
        password: this.autoGenPassword,
      };
      this.modalCallBack(true, payload);
    },
    modalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
  },
  mounted() {
    this.fetchAutoGenPassword();
  },
};
</script>